import React, { useEffect, useRef, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Avatar, Box, TextField, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import {
  GET_ALL_USERS,
  UPDATE_USER_PASSWORD,
  USER_REGISTRATION,
} from "../../constants/endpoints";
import AxiosServiceLoaderless from "../../constants/api";
import SearchForm from "../../components/SearchForm/SearchForm";
import PaginationFooter from "../../components/PaginationFooter/PaginationFooter";
import UsersCsv from "../../components/csv/UsersCsv";
import { CSVLink } from "react-csv";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import AxiosService from "../../constants/api";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    flex: "1 1 0%",
    boxSizing: "border-box",
  },
  searchForm: {
    background: "white",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1) * 2,
    display: "block",
    maxWidth: "800px",
  },
  searchInput: {
    fontSize: "1rem",
    padding: theme.spacing(1) * 1.9,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1) * 1.2,
    },
    cursor: "text",
    textIndent: "30px",
    border: "none",
    background: "transparent",
    width: "100%",
    outline: "0",
  },
  searchIcon: {
    position: "absolute",
    top: "50%",
    left: "0",
    marginTop: "-24px",
    color: "rgba(0,0,0,.87)",
  },
}));

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    overflowX: "auto",
  },
  subroot: {
    margin: theme.spacing(2),
    overflowX: "auto",
  },
  tableContainer: {
    maxHeight: "55vh", // Set the maximum height for the table body
    overflowY: "auto", // Enable vertical scrolling
  },
  table: {
    // marginRight:"20px"
  },
  title: {
    flex: "0 0 auto",
    padding: "20px",
  },
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    width: 60,
    height: 60,
  },
  pagination: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  loadingCell: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
});

const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT) || 100;

const ActiveUsers = ({ classes }) => {
  const history = useHistory();
  const searchClasses = useStyles();
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [fields, setFields] = useState([
    {
      name: "user_id",
      label: "User",
      type: "select",
      required: false,
      options: [],
    },
    {
      name: "createdFrom",
      label: "From",
      required: false,
      type: "date",
    },
    {
      name: "createdTo",
      label: "To",
      required: false,
      type: "date",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentHoveredImage, setCurrentHoveredImage] = useState(null);
  const [data, setData] = useState({
    rows: [],
    currentPage: 1,
    nextOffset: 0,
    lastOffset: 0,
    limit: limit,
    totalPages: 0,
  });
  const [csvData, setCsvData] = useState({ headers: [], data: [] });
  const [searchFields, setSearchFields] = useState(null);
  const csvLink = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Handle form submission
  const handleSubmit = async (row, popupState) => {
    if (!password || !row.user_id || !row.api_key) {
      return;
    }

    const data = {
      user_id: row.user_id,
      api_key: row.api_key,
      password: password,
    };
    try {
      const res = await AxiosService.post(UPDATE_USER_PASSWORD, data);
      if (res.status === 200) {
        popupState.close();
        toast.success("Password updated successfully.");
      }
    } catch (error) {
      toast.error(error.response);
      console.log("Error", error);
    }
  };

  const formatDataForCsv = async (data) => {
    const headers = [
      { label: "ID", key: "user_id" },
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Phone", key: "phone" },
      { label: "Created", key: "created" },
      { label: "First Name", key: "f_name" },
      { label: "Last Name", key: "l_name" },
      { label: "Username", key: "username" },
    ];

    const rows = Array.isArray(data)
      ? data.map((item) => ({
          user_id: item.user_id,
          name: item.name,
          email: item.email,
          phone: item.phone,
          created: new Date(item.created).toLocaleDateString(),
          f_name: item.f_name,
          l_name: item.l_name,
          username: item.username,
        }))
      : [];

    const isDownloadable = rows.length > 0;

    return { headers, rows, isDownloadable };
  };

  const getAllUsers = async (payload) => {
    try {
      const { currentPage, ...payloadData } = payload;
      setLoading(true);
      const res = await AxiosServiceLoaderless.post(GET_ALL_USERS, payloadData);
      if (res.status === 200) {
        setData((prev) => ({
          ...prev,
          rows: res.data.response || [],
          nextOffset: res.data.next_offset || 0,
          lastOffset: res.data.last_offset || 0,
          totalPages: res.data.total_records
            ? Math.ceil(parseInt(res.data.total_records) / parseInt(data.limit))
            : 0,
          currentPage: currentPage,
          totalRecords: res.data.total_records || 0,
        }));
        let updatedFields = fields.map((field) => {
          if (field.name === "user_id") {
            field.options = res.data.userlist;
          }
          return field;
        });
        // make download button visible, if total_records > 0
        if (res?.data?.total_records && res?.data?.total_records > 0) {
          setCsvData((prev) => ({ ...prev, isDownloadable: true }));
        } else {
          setCsvData((prev) => ({ ...prev, isDownloadable: false }));
        }
        setFields((prev) => updatedFields);
      }
    } catch (error) {
      console.log("Error:", error);
      setData((prev) => ({
        ...prev,
        rows: [],
        nextOffset: 0,
        lastOffset: 0,
        totalPages: 0,
        currentPage: payload.currentPage,
        limit: limit,
      }));
      setCsvData({ headers: [], data: [], isDownloadable: false });
    } finally {
      setLoading(false); // Set loading state to false after fetching data
    }
  };

  const handleSearch = (values) => {
    const data = {
      api_key: auth?.api_key,
      from: values?.createdFrom,
      to: values?.createdTo,
      user_id: values?.user_id,
      offset: 0,
      limit: limit,
      currentPage: 1,
    };
    // manage search fields in state
    setSearchFields(values);
    getAllUsers(data);
  };

  const handlePageChange = (pageNo) => {
    let payload = {
      api_key: auth?.api_key,
      limit: data.limit,
      offset: (pageNo - 1) * data.limit,
      currentPage: pageNo,
    };
    if (searchFields && Object.keys(searchFields)) {
      // if search fields are filled then update payload for pagination
      payload = { ...payload, ...searchFields };
    }
    getAllUsers(payload);
  };

  const handleImageOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentHoveredImage(index);
  };

  const handleImageClose = () => {
    setAnchorEl(null);
    setCurrentHoveredImage(null);
  };

  const handleCsv = async () => {
    try {
      let payloadData = {
        api_key: auth?.api_key,
        offset: 0,
        limit: data.totalRecords,
      };

      if (searchFields && Object.keys(searchFields)) {
        // add search fields to filter data for csv
        payloadData = { ...payloadData, ...searchFields };
      }

      const res = await AxiosServiceLoaderless.post(GET_ALL_USERS, payloadData);

      if (res.status === 200) {
        const { headers, rows, isDownloadable } = await formatDataForCsv(
          res.data.response || []
        );
        setCsvData({ headers, data: rows, isDownloadable: isDownloadable });
        setTimeout(() => {
          csvLink.current.link.click();
        }, 700);
      }
    } catch (error) {
      console.log("Error::", error);
      setCsvData({ headers: [], data: [], isDownloadable: false });
    }
  };

  useEffect(() => {
    const payload = {
      api_key: auth?.api_key,
      limit: data.limit,
      offset: data.nextOffset,
      currentPage: 1,
    };
    getAllUsers(payload);
  }, []);

  return (
    <Paper className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h4" id="tableTitle">
          Users
        </Typography>
      </div>
      <SearchForm
        fields={fields}
        onSubmit={handleSearch}
        handleCsv={handleCsv}
        isDownloadable={csvData.isDownloadable}
      />
      <Box className={classes.subroot}>
        {/* <Box className={classes.tableContainer}> */}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone No.</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Change Password</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} className={classes.loadingCell}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {data?.rows?.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={7} className={classes.loadingCell}>
                      <Typography variant="h6">No Record Found</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {data?.rows?.map((row, index) => (
                  <TableRow key={row.user_id}>
                    <TableCell>{row.user_id}</TableCell>
                    <TableCell>
                      <Box sx={{ cursor: "pointer" }}>
                        <Avatar
                          alt={row.name}
                          src={`${row.profile_image_path}`}
                          onMouseEnter={(event) =>
                            handleImageOpen(event, index)
                          }
                          onMouseLeave={handleImageClose}
                        />
                      </Box>
                      <Popper
                        open={currentHoveredImage === index}
                        anchorEl={anchorEl}
                        transition
                        sx={{ backgroundColor: "transparent" }}
                        placement="right"
                      >
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                              <Avatar
                                style={{ height: "100px", width: "100px" }}
                                alt={row.name}
                                src={`${row.profile_image_path}`}
                                variant="rounded"
                              />
                            </Paper>
                          </Fade>
                        )}
                      </Popper>
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.created}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          history.push(
                            `/user-assessment-detail/${row.user_id}`
                          );
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell>
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-popover"
                      >
                        {(popupState) => (
                          <div>
                            <Button
                              variant="contained"
                              color="primary"
                              {...bindTrigger(popupState)}
                            >
                              Change password
                            </Button>
                            <Popover
                              {...bindPopover(popupState)}
                              anchorReference="none" // Center the Popover on the screen
                              PaperProps={{
                                style: {
                                  margin: "auto",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  position: "absolute",
                                },
                              }}
                            >
                              <div
                                style={{ padding: "20px", textAlign: "center" }}
                              >
                                <Typography variant="h6">
                                  Change Password
                                </Typography>

                                <TextField
                                  label="New Password"
                                  variant="outlined"
                                  fullWidth
                                  margin="normal"
                                  type="password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)} // Handle password input
                                />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ marginTop: "10px" }}
                                  onClick={() => handleSubmit(row, popupState)} // Submit form
                                >
                                  Submit
                                </Button>
                              </div>
                            </Popover>
                          </div>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
        {/* </Box> */}
        <PaginationFooter
          currentPage={data.currentPage}
          totalPages={data.totalPages}
          onPageChange={handlePageChange}
        />
      </Box>
      <CSVLink
        data={csvData.data || data.rows}
        headers={csvData.headers}
        filename={`users_${new Date().toISOString()}.csv`}
        className="hidden"
        ref={csvLink}
      ></CSVLink>
    </Paper>
  );
};

export default withStyles(styles)(ActiveUsers);
